
export class DeepLinkHelper {

    getOnboardingRssLink(): string {
        return 'walkthrough://';
    }

    getMoreMenuRssLink(): string {
        return 'more://';
    }

    getHelpRssLink(): string {
        return 'help://';
    }

    getAvatarRssLink(): string {
        return 'interests://';
    }

    getLogoutRssLink(): string {
        return 'signout://';
    }

    getHomeRssLink(): string {
        return 'home://';
    }

    getTabsRssLink(): string {
        return 'tabsmenu://';
    }

    getWidgetOptionsRssLink(): string {
        return 'tabwidget://';
    }

    getRegionsRssLink(): string{
        return 'regions://'
    }

    getSeeAndDoRssLink(): string{
        return 'seeanddo://'
    }

    getSettingsRssLink(): string {
        return 'settings://';
    }

    parseParameters(url: string): { categoryid?: string | string[], subcategoryid?: string | string[], regionid?: string | string[] } {
        // Define case-insensitive regular expression patterns for parameters in the dine:// format
        const categoryIdPattern = /(?:[?&])CategoryID=([^&]+)/i;
        const subcategoryIdPattern = /(?:[?&])SubCategoryID=([^&]+)/i;
        const regionIdPattern = /(?:[?&])RegionID=([^&]+)/i;

        // Extract parameter values using regular expressions
        const categoryIdMatch = url.match(categoryIdPattern);
        const subcategoryIdMatch = url.match(subcategoryIdPattern);
        const regionIdMatch = url.match(regionIdPattern);

        // Extract parameter values if found
        const parameters: Record<string, string | string[]> = {};

        if (categoryIdMatch) {
            parameters['categoryid'] = parseValue(categoryIdMatch[1]);
        }

        if (subcategoryIdMatch) {
            parameters['subcategoryid'] = parseValue(subcategoryIdMatch[1]);
        }

        if (regionIdMatch) {
            parameters['regionid'] = parseValue(regionIdMatch[1]);
        }

        // Helper function to parse values
        function parseValue(value: string): string | string[] {
            if (isJsonArray(value)) {
                return parseJsonArray(value); // Parse JSON array format
            } else if (isCommaSeparated(value)) {
                return parseCommaSeparated(value); // Parse comma-separated format
            }
            return value; // Return as a string if no special format is detected
        }

        // Helper function to check if the value is a JSON array
        function isJsonArray(value: string): boolean {
            return /^\[.*\]$/.test(value);
        }

        // Helper function to parse JSON array values
        function parseJsonArray(value: string): string[] {
            try {
                return JSON.parse(value) as string[];
            } catch {
                return [value]; // Fallback to return the value as-is in an array if parsing fails
            }
        }

        // Helper function to check if the value is comma-separated
        function isCommaSeparated(value: string): boolean {
            return value.includes(',');
        }

        // Helper function to parse comma-separated values
        function parseCommaSeparated(value: string): string[] {
            return value.split(',').map(item => item.trim());
        }

        // Return the results
        return parameters;
    }


}
